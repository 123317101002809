module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"On The Block Real Estate International","short_name":"OnTheBlock","start_url":"/","background_color":"#f7f0eb","theme_color":"#9C2331","display":"standalone","icon":"src/images/OTB-App-Icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6cbbb5e9ccc401733ca3cd46b6c63e81"},
    },{
      plugin: require('../node_modules/gatsby-plugin-crisp-chat/gatsby-browser.js'),
      options: {"plugins":[],"websiteId":"43a967ee-7b72-4d9a-aba7-28d379702903","enableDuringDevelop":false,"defer":true,"enableImprovedAccessibility":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
